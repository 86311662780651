<template>
  <div>
    <p class="container" style="text-align: center">
      Bienvenido al portal de la CVC donde podrás registrar tus solicitudes en
      el proceso de Gestión Ambiental en el Territorio.
    </p>
    <!-- Inicio Acordeon -->
    <div class="container">
      <p>
        <center><b>PRESENTACIÓN DE COSTOS DE OPERACIÓN</b></center>
      </p>
      <div class="accordion" role="tablist">
        <!-- PRESENTACIÓN DE COSTOS DE OPERACION PARA SEGUIMIENTO -->
        <b-card no-body class="mb-1">
          <b-card-header header-tag="header" class="p-1" role="tab">
            <b-button
              block
              v-b-toggle.accordion-1
              variant="info"
              style="background: #3498db"
              >PRESENTACIÓN DE COSTOS DE OPERACIÓN PARA SEGUIMIENTO</b-button
            >
          </b-card-header>
          <b-collapse id="accordion-1" accordion="my-accordion" role="tabpanel">
            <b-card-body>
              <b-card
                no-body
                class="overflow-hidden"
                style="background: #f5f5f5"
              >
                <b-row no-gutters>
                  <b-col md="4">
                    <b-card-img
                      src="../../../img/Otros (16)_10x15.jpg"
                      alt="Image"
                      class="rounded-0"
                    ></b-card-img>
                  </b-col>
                  <b-col md="8">
                    <b-card-body title="">
                      <b-card-text style="text-align: justify">
                        Presentacion de costos requeridos para la
                        administracion, operacion y mantenimiento hasta la
                        desmantelacion del proyecto, obra o actividad.
                      </b-card-text>
                      <center>
                        <br />
                        <b-button href="/Costos" variant="outline-success">
                          <b-icon
                            icon="receipt-cutoff"
                            aria-hidden="true"
                          ></b-icon>
                          Diligencie aquí formulario en línea</b-button
                        >
                      </center>
                    </b-card-body>
                  </b-col>
                </b-row>
              </b-card>
            </b-card-body>
          </b-collapse>
        </b-card>
      </div>
      <br />
      <p>
        <center><b>REGISTROS AMBIENTALES</b></center>
      </p>
      <div class="accordion" role="tablist">
        <!-- INSCRIPCIÓN AL REGISTRO ÚNICO AMBIENTAL RUA MANUFACTURERO-->
        <b-card no-body class="mb-1">
          <b-card-header header-tag="header" class="p-1" role="tab">
            <b-button
              block
              v-b-toggle.accordion-5
              variant="info"
              style="background: #45b39d"
              >INSCRIPCIÓN AL REGISTRO ÚNICO AMBIENTAL RUA</b-button
            >
          </b-card-header>
          <b-collapse id="accordion-5" accordion="my-accordion" role="tabpanel">
            <b-card-body>
              <b-card
                no-body
                class="overflow-hidden"
                style="background: #f5f5f5"
              >
                <b-row no-gutters>
                  <b-col md="4">
                    <b-card-img
                      src="../../../img/rua.jpg"
                      alt="Image"
                      class="rounded-0"
                    ></b-card-img>
                  </b-col>
                  <b-col md="4">
                    <b-card-body title="">
                      <b-card-text style="text-align: justify; height: 135px">
                        Registro Único Ambiental (RUA), aplicable a los diferentes sectores productivos para el reporte de información sobre el uso y/o aprovechamiento de los recursos naturales renovables y las emisiones y transferencias de contaminantes. El cual mejora la integridad, la interoperabilidad, el reporte, la administración y las salidas de información.
                      </b-card-text>
                      <center>
                        <br />
                        <b-button
                          href="/FormularioRUA"
                          variant="outline-success"
                        >
                          <b-icon
                            icon="receipt-cutoff"
                            aria-hidden="true"
                          ></b-icon>
                          Diligencie aquí formulario en línea</b-button
                        >
                      </center>
                    </b-card-body>
                  </b-col>
                  <b-col md="4">
                    <b-card-body title="Información del trámite">
                      <b-card-text>
                        Registrar Inscripción solo en Ideam</b-card-text>
                        
                        <b-button
                          href="https://rua.ideam.gov.co/rua/login.jsf"
                          variant="outline-success"
                        >
                          <b-icon
                            icon="receipt-cutoff"
                            aria-hidden="true"
                          ></b-icon>
                          Ingresar</b-button><br><br>
                      

                      <b-card-text>
                        Consulte y/o Descargue el Formato</b-card-text>
                        
                        <b-button
                          style="width: 40%"
                          variant="outline-primary"
                          @click="abrirNuevoTab(rutas.rutaFormatoRua)"
                        >
                          <b-icon icon="paperclip" aria-hidden="true"></b-icon>
                          Formato
                        </b-button>
                      
                    </b-card-body>
                  </b-col>
                </b-row>
              </b-card>
            </b-card-body>
          </b-collapse>
        </b-card>
        <!--  INSCRIPCIÓN O ACTUALIZACIÓN DEL DEPARTAMENTO DE GESTIÓN AMBIENTAL-DGA-->
        <b-card no-body class="mb-1">
          <b-card-header header-tag="header" class="p-1" role="tab">
            <b-button
              block
              v-b-toggle.accordion-6
              variant="info"
              style="background: #45b39d"
              >INSCRIPCIÓN O ACTUALIZACIÓN DEL DEPARTAMENTO DE GESTIÓN
              AMBIENTAL-DGA</b-button
            >
          </b-card-header>
          <b-collapse id="accordion-6" accordion="my-accordion" role="tabpanel">
            <b-card-body>
              <b-card
                no-body
                class="overflow-hidden"
                style="background: #f5f5f5"
              >
                <b-row no-gutters>
                  <b-col md="4">
                    <b-card-img
                      src="../../../img/DGA1.JPG"
                      alt="Image"
                      class="rounded-0"
                    ></b-card-img>
                  </b-col>
                  <b-col md="4">
                    <b-card-body title="">
                      <b-card-text
                        style="
                          text-align: justify;
                          height: 135px;
                          font-size: 14px;
                        "
                      >
                        Área de gestión ambiental de empresas a nivel
                        industrial; responsable de velar por el cumplimiento de
                        la normatividad ambiental; prevenir, minimizar y
                        controlar la generación de cargas contaminantes;
                        prácticas de producción más limpia, uso racional de los
                        recursos naturales; uso de combustible limpios; proteger
                        y conservar los ecosistemas.
                      </b-card-text>
                      <center>
                        <br />
                        <b-button
                          href="/FormularioDGA"
                          variant="outline-success"
                        >
                          <b-icon
                            icon="receipt-cutoff"
                            aria-hidden="true"
                          ></b-icon>
                          Diligencie aquí formulario en línea</b-button
                        >
                      </center>
                    </b-card-body>
                  </b-col>
                  <b-col md="4">
                    <b-card-body title="Información del trámite">
                      <b-card-text>
                        Consulte y/o Descargue el Formato
                        <br /><br />
                        <b-button
                          style="width: 40%"
                          variant="outline-primary"
                          @click="abrirNuevoTab(rutas.rutaFormatoDGA)"
                        >
                          <b-icon icon="paperclip" aria-hidden="true"></b-icon>
                          Formato
                        </b-button>
                      </b-card-text>
                    </b-card-body>
                  </b-col>
                </b-row>
              </b-card>
            </b-card-body>
          </b-collapse>
        </b-card>
        <!-- INSCRIPCIÓN EN EL REGISTRO DE GENERADORES DE RESIDUOS O DESECHOS PELIGROSOS – RESPEL -->
        <b-card no-body class="mb-1">
          <b-card-header header-tag="header" class="p-1" role="tab">
            <b-button
              block
              v-b-toggle.accordion-7
              variant="info"
              style="background: #45b39d"
              >INSCRIPCIÓN EN EL REGISTRO DE GENERADORES DE RESIDUOS O DESECHOS
              PELIGROSOS – RESPEL</b-button
            >
          </b-card-header>
          <b-collapse id="accordion-7" accordion="my-accordion" role="tabpanel">
            <b-card-body>
              <b-card
                no-body
                class="overflow-hidden"
                style="background: #f5f5f5"
              >
                <b-row no-gutters>
                  <b-col md="4">
                    <b-card-img
                      src="../../../img/respel.jpg"
                      alt="Image"
                      class="rounded-0"
                    ></b-card-img>
                  </b-col>
                  <b-col md="4">
                    <b-card-body title="">
                      <b-card-text
                        style="
                          text-align: justify;
                          height: 135px;
                          font-size: 14px;
                        "
                      >
                        Instrumento de gestión de captura información homogénea
                        y sistemática sobre generación de residuos o desechos
                        peligrosos, originados por actividades productivas y
                        sectoriales del país, lo cual contribuirá a mejorar el
                        conocimiento de la problemática, la planificación de su
                        gestión y el establecimiento de acciones asociada a este
                        tipo de residuos.
                      </b-card-text>
                      <center>
                        <br />
                        <b-button
                          href="/FormularioRespel"
                          variant="outline-success"
                        >
                          <b-icon
                            icon="receipt-cutoff"
                            aria-hidden="true"
                          ></b-icon>
                          Diligencie aquí formulario en línea</b-button
                        >
                      </center>
                    </b-card-body>
                  </b-col>
                  <b-col md="4">
                    <b-card-body title="Información del trámite">
                      <b-card-text>
                        Consulte y/o Descargue el Formato
                        <br /><br />
                        <b-button
                          style="width: 40%"
                          variant="outline-primary"
                          @click="abrirNuevoTab(rutas.rutaFormatoRespel)"
                        >
                          <b-icon icon="paperclip" aria-hidden="true"></b-icon>
                          Formato
                        </b-button>
                      </b-card-text>
                    </b-card-body>
                  </b-col>
                </b-row>
              </b-card>
            </b-card-body>
          </b-collapse>
        </b-card>
        <!--  INSCRIPCIÓN DE GENERADOR INDUSTRIAL, COMERCIAL O DE SERVICIOS DE ACEITES DE COCINA USADOS - ACU-->
        <b-card no-body class="mb-1">
          <b-card-header header-tag="header" class="p-1" role="tab">
            <b-button
              block
              v-b-toggle.accordion-8
              variant="info"
              style="background: #45b39d"
            >
              INSCRIPCIÓN DE GENERADOR INDUSTRIAL, COMERCIAL O DE SERVICIOS DE
              ACEITES DE COCINA USADOS - ACU</b-button
            >
          </b-card-header>
          <b-collapse id="accordion-8" accordion="my-accordion" role="tabpanel">
            <b-card-body>
              <b-card
                no-body
                class="overflow-hidden"
                style="background: #f5f5f5"
              >
                <b-row no-gutters>
                  <b-col md="4">
                    <b-card-img
                      src="../../../img/ACU_GENERADOR.JPG"
                      alt="Image"
                      class="rounded-0"
                    ></b-card-img>
                  </b-col>
                  <b-col md="4">
                    <b-card-body title="">
                      <b-card-text style="text-align: justify; height: 135px">
                        Toda persona industrial, comercial y de servicios que
                        genere ACU deberán inscribirse ante la Autoridad
                        Ambiental competente en el área donde se realizará la
                        actividad de generación. Información necesaria para
                        ejercer el control de las obligaciones establecidas en
                        la normativa.
                      </b-card-text>
                      <center>
                        <br />
                        <b-button
                          href="/FormularioACU"
                          variant="outline-success"
                        >
                          <b-icon
                            icon="receipt-cutoff"
                            aria-hidden="true"
                          ></b-icon>
                          Diligencie aquí formulario en línea</b-button
                        >
                      </center>
                    </b-card-body>
                  </b-col>
                  <b-col md="4">
                    <b-card-body title="Información del trámite">
                      <b-card-text>
                        Consulte y/o Descargue el Formato.
                        <br /><br />
                        <b-button
                          style="width: 40%"
                          variant="outline-primary"
                          @click="abrirNuevoTab(rutas.rutaFormatoGeneradorACU)"
                        >
                          <b-icon icon="paperclip" aria-hidden="true"></b-icon>
                          Formato </b-button
                        ><br />
                      </b-card-text>
                    </b-card-body>
                  </b-col>
                </b-row>
              </b-card>
            </b-card-body>
          </b-collapse>
        </b-card>
        <!--  FORMULARIO REPORTE ANUAL DE ACEITA DE COCINA USADO - ACU PARA GESTORES-->
        <b-card no-body class="mb-1">
          <b-card-header header-tag="header" class="p-1" role="tab">
            <b-button
              block
              v-b-toggle.accordion-18
              variant="info"
              style="background: #45b39d"
            >
              REPORTE ANUAL DE ACEITE DE COCINA USADO - ACU PARA
              GENERADORES</b-button
            >
          </b-card-header>
          <b-collapse id="accordion-18" accordion="my-accordion" role="tabpanel">
            <b-card-body>
              <b-card
                no-body
                class="overflow-hidden"
                style="background: #f5f5f5"
              >
                <b-row no-gutters>
                  <b-col md="4">
                    <b-card-img
                      src="../../../img/REPORTE ANUAL.JPG"
                      alt="Image"
                      class="rounded-0"
                    ></b-card-img>
                  </b-col>
                  <b-col md="4">
                    <b-card-body title="">
                      <b-card-text style="text-align: justify; height: 135px">
                        El reporte anual para generdores de ACU, debe realizarse
                        hasta el 15 de enero de cada año, de vital importancia
                        en marco del cumplimiento de la normativa. Información
                        que se utiliza de base para ejercer el control y
                        verificación de cumplimiento de las obligaciones
                        establecidas en la normativa.
                      </b-card-text>
                      <center>
                        <br />
                        <b-button
                          href="/FormularioRAACUG"
                          variant="outline-success"
                        >
                          <b-icon
                            icon="receipt-cutoff"
                            aria-hidden="true"
                          ></b-icon>
                          Diligencie aquí formulario en línea</b-button
                        >
                      </center>
                    </b-card-body>
                  </b-col>
                  <b-col md="4">
                    <b-card-body title="Información del trámite">
                      <b-card-text>
                        Consulte y/o Descargue el Formato a Diligenciar.
                        <br /><br />
                        <b-button
                          style="width: 40%"
                          variant="outline-primary"
                          @click="abrirNuevoTab(rutas.rutaReporteanualGene)"
                        >
                          <b-icon icon="paperclip" aria-hidden="true"></b-icon>
                          Formato
                        </b-button>
                      </b-card-text>
                    </b-card-body>
                  </b-col>
                </b-row>
              </b-card>
            </b-card-body>
          </b-collapse>
        </b-card>
        <!--  FORMULARIO SOLICITUD DE INSCRIPCIÓN PARA GESTOR DE ACEITE DE COCINA USADO - ACU-->
        <b-card no-body class="mb-1">
          <b-card-header header-tag="header" class="p-1" role="tab">
            <b-button
              block
              v-b-toggle.accordion-10
              variant="info"
              style="background: #45b39d"
            >
              SOLICITUD DE INSCRIPCIÓN PARA GESTOR DE ACEITE DE COCINA USADO -
              ACU</b-button
            >
          </b-card-header>
          <b-collapse
            id="accordion-10"
            accordion="my-accordion"
            role="tabpanel"
          >
            <b-card-body>
              <b-card
                no-body
                class="overflow-hidden"
                style="background: #f5f5f5"
              >
                <b-row no-gutters>
                  <b-col md="4">
                    <b-card-img
                      src="../../../img/ACU_GESTOR.JPG"
                      alt="Image"
                      class="rounded-0"
                    ></b-card-img>
                  </b-col>
                  <b-col md="4">
                    <b-card-body title="">
                      <b-card-text style="text-align: justify; height: 135px">
                        Toda persona que sea gestor de ACU deberá inscribirse
                        ante la Autoridad Ambiental competente en el área donde
                        se realice la actividad de recolección, tratamiento y/o
                        aprovechamiento de ACU. Información necesaria para
                        ejercer el control de las obligaciones establecidas en
                        la normativa.
                      </b-card-text>
                      <center>
                        <br />
                        <b-button
                          href="/FormularioGestorACU"
                          variant="outline-success"
                        >
                          <b-icon
                            icon="receipt-cutoff"
                            aria-hidden="true"
                          ></b-icon>
                          Diligencie aquí formulario en línea</b-button
                        >
                      </center>
                    </b-card-body>
                  </b-col>
                  <b-col md="4">
                    <b-card-body title="Información del trámite">
                      <b-card-text>
                        Consulte y/o Descargue el Formato.
                        <br /><br />
                        <b-button
                          style="width: 40%"
                          variant="outline-primary"
                          @click="abrirNuevoTab(rutas.rutaFormatoGestorACU)"
                        >
                          <b-icon icon="paperclip" aria-hidden="true"></b-icon>
                          Formato </b-button
                        ><br />
                      </b-card-text>
                    </b-card-body>
                  </b-col>
                </b-row>
              </b-card>
            </b-card-body>
          </b-collapse>
        </b-card>
        <!--  FORMULARIO REPORTE ANUAL DE ACEITA DE COCINA USADO - ACU PARA GESTORES-->
        <b-card no-body class="mb-1">
          <b-card-header header-tag="header" class="p-1" role="tab">
            <b-button
              block
              v-b-toggle.accordion-9
              variant="info"
              style="background: #45b39d"
            >
              REPORTE ANUAL DE ACEITE DE COCINA USADO - ACU PARA
              GESTORES</b-button
            >
          </b-card-header>
          <b-collapse id="accordion-9" accordion="my-accordion" role="tabpanel">
            <b-card-body>
              <b-card
                no-body
                class="overflow-hidden"
                style="background: #f5f5f5"
              >
                <b-row no-gutters>
                  <b-col md="4">
                    <b-card-img
                      src="../../../img/REPORTE ANUAL.JPG"
                      alt="Image"
                      class="rounded-0"
                    ></b-card-img>
                  </b-col>
                  <b-col md="4">
                    <b-card-body title="">
                      <b-card-text style="text-align: justify; height: 135px">
                        El reporte anual para gestores de ACU, debe realizarse
                        hasta el 15 de enero de cada año, de vital importancia
                        en marco del cumplimiento de la normativa. Información
                        que se utiliza de base para ejercer el control y
                        verificación de cumplimiento de las obligaciones
                        establecidas en la normativa.
                      </b-card-text>
                      <center>
                        <br />
                        <b-button
                          href="/FormularioRAACU"
                          variant="outline-success"
                        >
                          <b-icon
                            icon="receipt-cutoff"
                            aria-hidden="true"
                          ></b-icon>
                          Diligencie aquí formulario en línea</b-button
                        >
                      </center>
                    </b-card-body>
                  </b-col>
                  <b-col md="4">
                    <b-card-body title="Información del trámite">
                      <b-card-text>
                        Consulte y/o Descargue el Formato a Diligenciar.
                        <br /><br />
                        <b-button
                          style="width: 40%"
                          variant="outline-primary"
                          @click="abrirNuevoTab(rutas.rutaReporteanual)"
                        >
                          <b-icon icon="paperclip" aria-hidden="true"></b-icon>
                          Formato
                        </b-button>
                      </b-card-text>
                    </b-card-body>
                  </b-col>
                </b-row>
              </b-card>
            </b-card-body>
          </b-collapse>
        </b-card>
        
        <!-- ANEXO IV. INSCRIPCIÓN DE LAS EMPRESAS TRANSFORMADORAS ANTE LA AUTORIDAD AMBIENTAL COMPETENTE -->
        <b-card no-body class="mb-1">
          <b-card-header header-tag="header" class="p-1" role="tab">
            <b-button
              block
              v-b-toggle.accordion-11
              variant="info"
              style="background: #45b39d"
              >ANEXO IV. INSCRIPCIÓN DE LAS EMPRESAS TRANSFORMADORAS ANTE LA
              AUTORIDAD AMBIENTAL COMPETENTE</b-button
            >
          </b-card-header>
          <b-collapse
            id="accordion-11"
            accordion="my-accordion"
            role="tabpanel"
          >
            <b-card-body>
              <b-card
                no-body
                class="overflow-hidden"
                style="background: #f5f5f5"
              >
                <b-row no-gutters>
                  <b-col md="4">
                    <b-card-img
                      src="../../../img/dga.jpg"
                      alt="Image"
                      class="rounded-0"
                    ></b-card-img>
                  </b-col>
                  <b-col md="4">
                    <b-card-body title="">
                      <b-card-text
                        style="
                          text-align: justify;
                          height: 135px;
                          font-size: 14px;
                        "
                      >
                        Empresas transformadoras deben cumplir con los
                        requisitos técnicos y legales para certificar las
                        toneladas aprovechadas al Plan de Gestión ambiental de
                        Residuos de Envases y Empaques, la medición de la
                        eficiencia de retornabilidad que permite identificar la
                        cantidad de envases y empaques retornables, que, no
                        ingresan al circuito de retorno del productor.
                      </b-card-text>
                      <center>
                        <br />
                        <b-button
                          href="/FormularioITE"
                          variant="outline-success"
                        >
                          <b-icon
                            icon="receipt-cutoff"
                            aria-hidden="true"
                          ></b-icon>
                          Diligencie aquí formulario en línea</b-button
                        >
                      </center>
                    </b-card-body>
                  </b-col>
                  <b-col md="4">
                    <b-card-body title="Información del trámite">
                      <b-card-text>
                        Consulte y/o Descargue el Formato
                        <br /><br />
                        <b-button
                          style="width: 40%"
                          variant="outline-primary"
                          @click="abrirNuevoTab(rutas.rutaFormatoIET)"
                        >
                          <b-icon icon="paperclip" aria-hidden="true"></b-icon>
                          Formato
                        </b-button>
                      </b-card-text>
                    </b-card-body>
                  </b-col>
                </b-row>
              </b-card>
            </b-card-body>
          </b-collapse>
        </b-card>
        <!-- ANEXO IV. FORMATO PARA LA INSCRIPCIÓN DE GESTORES DE LLANTAS USADAS ANTE LA AUTORIDAD AMBIENTAL COMPETENTE-->
        <b-card no-body class="mb-1">
          <b-card-header header-tag="header" class="p-1" role="tab">
            <b-button
              block
              v-b-toggle.accordion-12
              variant="info"
              style="background: #45b39d"
              >ANEXO IV. FORMULARIO PARA LA INSCRIPCIÓN DE GESTORES DE LLANTAS
              USADAS ANTE LA AUTORIDAD AMBIENTAL COMPETENTE
            </b-button>
          </b-card-header>
          <b-collapse
            id="accordion-12"
            accordion="my-accordion"
            role="tabpanel"
          >
            <b-card-body>
              <b-card
                no-body
                class="overflow-hidden"
                style="background: #f5f5f5"
              >
                <b-row no-gutters>
                  <b-col md="4">
                    <b-card-img
                      src="../../../img/llantas.png"
                      alt="Image"
                      class="rounded-0"
                    ></b-card-img>
                  </b-col>
                  <b-col md="4">
                    <b-card-body title="">
                      <b-card-text
                        style="
                          text-align: justify;
                          height: 135px;
                          font-size: 14px;
                        "
                      >
                        Toda Persona natural o jurídica que realiza actividades
                        de reencauche, almacenamiento y aprovechamiento de
                        llantas usadas, debe Inscribirse ante la autoridad
                        ambiental competente en el área donde desarrolla sus
                        actividades, según lo dispuesto en el formato del anexo
                        IV de la presente resolución.
                      </b-card-text>
                      <center>
                        <br />
                        <b-button
                          href="/FormularioILLANTAS"
                          variant="outline-success"
                        >
                          <b-icon
                            icon="receipt-cutoff"
                            aria-hidden="true"
                          ></b-icon>
                          Diligencie aquí formulario en línea</b-button
                        >
                      </center>
                    </b-card-body>
                  </b-col>
                  <b-col md="4">
                    <b-card-body title="Información del trámite">
                      <b-card-text>
                        Consulte y/o Descargue el Formato.
                        <br /><br />
                        <b-button
                          style="width: 40%"
                          variant="outline-primary"
                          @click="abrirNuevoTab(rutas.rutaFormatoLlantas)"
                        >
                          <b-icon icon="paperclip" aria-hidden="true"></b-icon>
                          Formato </b-button
                        ><br />
                      </b-card-text>
                    </b-card-body>
                  </b-col>
                </b-row>
              </b-card>
            </b-card-body>
          </b-collapse>
        </b-card>
        <!-- ANEXO IV. FORMATO PARA LA INSCRIPCIÓN DE GESTORES DE RCD ANTE LA AUTORIDAD AMBIENTAL COMPETENTE-->
        <b-card no-body class="mb-1">
          <b-card-header header-tag="header" class="p-1" role="tab">
            <b-button
              block
              v-b-toggle.accordion-13
              variant="info"
              style="background: #45b39d"
              >ANEXO IV. FORMATO PARA LA INSCRIPCIÓN DE GESTORES DE RCD ANTE LA
              AUTORIDAD AMBIENTAL COMPETENTE</b-button
            >
          </b-card-header>
          <b-collapse
            id="accordion-13"
            accordion="my-accordion"
            role="tabpanel"
          >
            <b-card-body>
              <b-card
                no-body
                class="overflow-hidden"
                style="background: #f5f5f5"
              >
                <b-row no-gutters>
                  <b-col md="4">
                    <b-card-img
                      src="../../../img/RCD.png"
                      alt="Image"
                      class="rounded-0"
                    ></b-card-img>
                  </b-col>
                  <b-col md="4">
                    <b-card-body title="">
                      <b-card-text
                        style="
                          text-align: justify;
                          height: 135px;
                          font-size: 14px;
                        "
                      >
                        Toda persona natural o jurídica, pública o privada que
                        desarrolle actividades de recolección, transporte,
                        almacenamiento, aprovechamiento y/o disposición final de
                        Residuos de Construcción y Demolición - RCD, debe
                        inscribirse ante la autoridad ambiental competente.
                        Información utilizada para el seguimiento al cumplimiento de las obligaciones
                        establecidas en la normativa.
                      </b-card-text>
                      <center>
                        <br />
                        <b-button
                          href="/FormularioRCD"
                          variant="outline-success"
                        >
                          <b-icon
                            icon="receipt-cutoff"
                            aria-hidden="true"
                          ></b-icon>
                          Diligencie aquí formulario en línea</b-button
                        >
                      </center>
                    </b-card-body>
                  </b-col>
                  <b-col md="4">
                    <b-card-body title="Información del trámite">
                      <b-card-text>
                        Consulte y/o Descargue el Formato.
                        <br /><br />
                        <b-button
                          style="width: 40%"
                          variant="outline-primary"
                          @click="abrirNuevoTab(rutas.rutaFormatoRCD)"
                        >
                          <b-icon icon="paperclip" aria-hidden="true"></b-icon>
                          Formato </b-button
                        ><br />
                      </b-card-text>
                    </b-card-body>
                  </b-col>
                </b-row>
              </b-card>
            </b-card-body>
          </b-collapse>
        </b-card>
        <!-- ACTIVACIÓN DE INSCRIPCIÓN EN EL INVENTARIO NACIONAL DE PCB -->
        <b-card no-body class="mb-1">
          <b-card-header header-tag="header" class="p-1" role="tab">
            <b-button
              block
              v-b-toggle.accordion-14
              variant="info"
              style="background: #45b39d"
              >ACTIVACIÓN DE INSCRIPCIÓN EN EL INVENTARIO NACIONAL DE
              PCB</b-button
            >
          </b-card-header>
          <b-collapse
            id="accordion-14"
            accordion="my-accordion"
            role="tabpanel"
          >
            <b-card-body>
              <b-card
                no-body
                class="overflow-hidden"
                style="background: #f5f5f5"
              >
                <b-row no-gutters>
                  <b-col md="4">
                    <b-card-img
                      src="../../../img/Concesión de Aguas Superficiales.jpg"
                      alt="Image"
                      class="rounded-0"
                    ></b-card-img>
                  </b-col>
                  <b-col md="8">
                    <b-card-body title="">
                      <b-card-text style="text-align: justify; height: 135px">
                      </b-card-text>
                      <center>
                        <br />
                        <b-button
                          href="/FormularioPCB"
                          variant="outline-success"
                        >
                          <b-icon
                            icon="receipt-cutoff"
                            aria-hidden="true"
                          ></b-icon>
                          Diligencie aquí formulario en línea</b-button
                        >
                      </center>
                    </b-card-body>
                  </b-col>
                </b-row>
              </b-card>
            </b-card-body>
          </b-collapse>
        </b-card>
        <!-- REGISTRO DE ESTABLECIMIENTOS DEDICADOS A LA EXPLOTACIÓN DE RECURSOS DE FLORA Y FAUNA -->
        <b-card no-body class="mb-1">
          <b-card-header header-tag="header" class="p-1" role="tab">
            <b-button
              block
              v-b-toggle.accordion-15
              variant="info"
              style="background: #45b39d"
              >REGISTRO DE ESTABLECIMIENTOS DEDICADOS A LA EXPLOTACIÓN DE
              RECURSOS DE FLORA Y FAUNA</b-button
            >
          </b-card-header>
          <b-collapse
            id="accordion-15"
            accordion="my-accordion"
            role="tabpanel"
          >
            <b-card-body>
              <b-card
                no-body
                class="overflow-hidden"
                style="background: #f5f5f5"
              >
                <b-row no-gutters>
                  <b-col md="4">
                    <b-card-img
                      src="../../../img/Registro de Establecimientos Explotación Fauna y Flora_10x15.jpg"
                      alt="Image"
                      class="rounded-0"
                    ></b-card-img>
                  </b-col>
                  <b-col md="8">
                    <b-card-body title="">
                      <b-card-text style="text-align: justify; height: 135px">
                        Es el registro de las empresas dedicadas a la
                        transformación primaria, transformación secundaria,
                        comercialización y transformación secundaria y las
                        integradas de productos forestales, de los viveros
                        forestales, criaderos de flora silvestre, huertos o
                        rodales semilleros.
                      </b-card-text>
                      <center>
                        <br />
                        <b-button
                          href="/Regisrtroederff"
                          variant="outline-success"
                        >
                          <b-icon
                            icon="receipt-cutoff"
                            aria-hidden="true"
                          ></b-icon>
                          Diligencie aquí formulario en línea</b-button
                        >
                      </center>
                    </b-card-body>
                  </b-col>
                  <!--<b-col md="4">
                    <b-card-body title="Información del trámite">
                      <b-card-text>
                        Consulte y/o Descargue el Formulario Único Nacional.
                        <br />
                        <b-button
                          style="width: 40%"
                          variant="outline-primary"
                          @click="abrirNuevoTab(rutas.rutaf1a)"
                        >
                          <b-icon icon="paperclip" aria-hidden="true"></b-icon>
                          FUN </b-button
                        ><br />
                        Consulte y/o Descargue información sobre anexos
                        necesarios. <br />
                        <b-button
                          style="width: 40%"
                          variant="outline-primary"
                          @click="abrirNuevoTab(rutas.rutaf1a)"
                        >
                          <b-icon icon="paperclip" aria-hidden="true"></b-icon>
                          Anexos
                        </b-button>
                      </b-card-text>
                    </b-card-body>
                  </b-col>-->
                </b-row>
              </b-card>
            </b-card-body>
          </b-collapse>
        </b-card>
        <!-- REGISTRO DE PLANTACIONES PROTECTORAS, PROTECTORAS - PRODUCTORAS -->
        <b-card no-body class="mb-1">
          <b-card-header header-tag="header" class="p-1" role="tab">
            <b-button
              block
              v-b-toggle.accordion-16
              variant="info"
              style="background: #45b39d"
              >REGISTRO DE PLANTACIONES PROTECTORAS, PROTECTORAS -
              PRODUCTORAS</b-button
            >
          </b-card-header>
          <b-collapse
            id="accordion-16"
            accordion="my-accordion"
            role="tabpanel"
          >
            <b-card-body>
              <b-card
                no-body
                class="overflow-hidden"
                style="background: #f5f5f5"
              >
                <b-row no-gutters>
                  <b-col md="4">
                    <b-card-img
                      src="../../../img/Otros (9)_10x15.jpg"
                      alt="Image"
                      class="rounded-0"
                    ></b-card-img>
                  </b-col>
                  <b-col md="4">
                    <b-card-body title="">
                      <b-card-text style="text-align: justify; height: 135px">
                        Es el acto administrativo motivado por el cual se
                        procede a registrar las plantaciones forestales
                        protectoras, productoras y protectoras.
                      </b-card-text>
                      <center>
                        <br />
                        <b-button href="/Regisrtropp" variant="outline-success">
                          <b-icon
                            icon="receipt-cutoff"
                            aria-hidden="true"
                          ></b-icon>
                          Diligencie aquí formulario en línea</b-button
                        >
                      </center>
                    </b-card-body>
                  </b-col>
                  <b-col md="4">
                    <b-card-body title="Información del trámite">
                      <b-card-text>
                        Consulte y/o Descargue el Formato.
                        <br /><br />
                        <b-button
                          style="width: 40%"
                          variant="outline-primary"
                          @click="abrirNuevoTab(rutas.rutaFormatoPlantaciones)"
                        >
                          <b-icon icon="paperclip" aria-hidden="true"></b-icon>
                          Formato </b-button
                        ><br />
                      </b-card-text>
                    </b-card-body>
                  </b-col>
                </b-row>
              </b-card>
            </b-card-body>
          </b-collapse>
        </b-card>
        <!-- REGISTRO DEL LIBRO DE OPERACIONES DE INDUSTRIAS O EMPRESAS FORESTALES-->
        <b-card no-body class="mb-1">
          <b-card-header header-tag="header" class="p-1" role="tab">
            <b-button
              block
              v-b-toggle.accordion-17
              variant="info"
              style="background: #45b39d"
              >REGISTRO DEL LIBRO DE OPERACIONES DE INDUSTRIAS O EMPRESAS
              FORESTALES</b-button
            >
          </b-card-header>
          <b-collapse
            id="accordion-17"
            accordion="my-accordion"
            role="tabpanel"
          >
            <b-card-body>
              <b-card
                no-body
                class="overflow-hidden"
                style="background: #f5f5f5"
              >
                <b-row no-gutters>
                  <b-col md="4">
                    <b-card-img
                      src="../../../img/Concesión de Aguas Superficiales.jpg"
                      alt="Image"
                      class="rounded-0"
                    ></b-card-img>
                  </b-col>
                  <b-col md="8">
                    <b-card-body title="">
                      <b-card-text style="text-align: justify; height: 135px">
                        Es el acto administrativo motivado por el cual se
                        procede a registrar el libro de operaciones de las
                        industrias o empresas forestales.
                      </b-card-text>
                      <center>
                        <br />
                        <b-button
                          href="/Regisrtroloief"
                          variant="outline-success"
                        >
                          <b-icon
                            icon="receipt-cutoff"
                            aria-hidden="true"
                          ></b-icon>
                          Diligencie aquí formulario en línea</b-button
                        >
                      </center>
                    </b-card-body>
                  </b-col>
                  <!--<b-col md="4">
                    <b-card-body title="Información del trámite">
                      <b-card-text>
                        Consulte y/o Descargue el Formulario Único Nacional.
                        <br />
                        <b-button
                          style="width: 40%"
                          variant="outline-primary"
                          @click="abrirNuevoTab(rutas.rutaf1a)"
                        >
                          <b-icon icon="paperclip" aria-hidden="true"></b-icon>
                          FUN </b-button
                        ><br />
                        Consulte y/o Descargue información sobre anexos
                        necesarios. <br />
                        <b-button
                          style="width: 40%"
                          variant="outline-primary"
                          @click="abrirNuevoTab(rutas.rutaf1a)"
                        >
                          <b-icon icon="paperclip" aria-hidden="true"></b-icon>
                          Anexos
                        </b-button>
                      </b-card-text>
                    </b-card-body>
                  </b-col>-->
                </b-row>
              </b-card>
            </b-card-body>
          </b-collapse>
        </b-card>
      </div>
    </div>
    <br />
  </div>
</template>

<script>
import {} from "../properties";
export default {
  data() {
    return {
      show: true,
      selected: null,
      rutas: {
        rutaFormatoIET: "../../documentos/ANEXO IV INS_TRANSFORMADORAS.pdf",
        rutaFormatoRua: "../../documentos/Anexo 2 Resolucion 0839 de 2023 Formato inscripcion RUA.pdf",
        rutaFormatoRespel: "../../documentos/RESPEL_FORMATO.pdf",
        rutaFormatoDGA:
          "../../documentos/DGA FT.0340.20 V03 20221012 Inscripcion o actualizacion.pdf",
        rutaFormatoPlantaciones:
          "../../documentos/FUN-Registro-plantaciones-forestales-protectoras-y-o-pro.pdf",
        rutaFormatoGeneradorACU: "../../documentos/ACU_GEN_FT.0340.64_.pdf",
        rutaReporteanual:
          "../../documentos/FT.0340.66 V01 20221130 Reporte anual  de aceite de cocina usado - ACU - Para gestores.xlsx",
        rutaFormatoGestorACU: "../../documentos/ACU_GES_FT.0340.65 V01_.pdf",
        rutaFormatoLlantas: "../../documentos/LLANTAS_GES_ANEXOS IV.pdf",
        rutaFormatoRCD: "../../documentos/InscripcionDGA.pdf",
        rutaReporteanualGene: "../../FT.0340.XX_ Reporte anual  de aceite de cocina usado - ACU - Para GENERADORES.xlsx",
      },
    };
  },

  methods: {
    abrirNuevoTab(param) {
      // Abrir nuevo tab
      var url = param;
      var win = window.open(url, "_blank");
      // Cambiar el foco al nuevo tab (punto opcional)
      win.focus();
    },
  },
  components: {},
  name: "HelloWorld",
  props: {
    msg: String,
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #000000;
}
</style>
